<div *ngIf="company" class="marketplace-view-container">
	<div class="company-title">
		{{ getPrefix(company.titleVariants[0].prefix) }} «{{ company.titleVariants[0].title }}»
	</div>

	<div class="main-container">
		<div class="column">
			<div class="tags">
				<div class="country">Germany</div>
				<div class="service">Consulting</div>
				<div class="icons-container">
					<img class="icon" src="assets/icons/coin-background-icon.svg">
					<img class="icon" src="assets/icons/user-background-icon.svg">
					<img class="icon" src="assets/icons/verified-background-icon.svg">
				</div>
			</div>

			<div class="image-container">
				<img [src]="company.companyImageUrl">
			</div>

			<div class="price-container">
				<div class="currency">
					{{ company.currency }} 
				</div>
				<div class="price">
					{{ company.priceForSale }}
				</div>
			</div>

			<button class="buy-button-container" (click)="onBuyClick()">Buy</button>
		</div>
		<div class="column">
			<div class="properties-container">
				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/setting-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Field of activity</div>
						<div class="value">Field of activity</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/timer-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Date of registration</div>
						<div class="value">{{ getRegistrationDate() }}</div>
					</div>
				</div>
				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/project-recording-outline-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Form of company</div>
						<div class="value">{{ getPrefix(company.titleVariants[0].prefix) }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/home-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Property</div>
						<div class="value">Premises 1200 m2</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/cash-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Revenue, millions</div>
						<div class="value">{{ company.currency }} 1,4</div>
					</div>
				</div>

			</div>
		</div>
		<div class="column">
			<div class="properties-container">
				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/card-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Bank accounts</div>
						<div class="value">Oychebank</div>
						<div class="value">Sparskassa</div>
					</div>
				</div>

					<div class="property-item">
						<div class="property-icon-container">
							<img class="property-icon" src="assets/icons/tick-outlined-icon.svg">
						</div>
						<div class="text-container">
							<div class="title">Reporting</div>
							<div class="value">In progress</div>
							<div class="value">Passed</div>
							<div class="value">Audit completed for the last year</div>
						</div>
					</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/online-shopping-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Selling as a ready-made business</div>
						<div class="value">No</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/namecard-outlined-icon-2.svg">
					</div>
					<div class="text-container">
						<div class="title">Possibility of obtaining citizenship</div>
						<div class="value">No</div>
					</div>
				</div>
		</div>
	</div>
</div>