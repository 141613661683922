import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@environment/environment';
import { SharedService } from 'app/store/Shared/services/SharedServices';

import {
  getCompaniesForSaleList,
  getCompaniesForSaleListSuccess,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-marketplace',
  templateUrl: './ui/marketplace.component.html',
  styleUrls: ['./ui/marketplace.component.scss'],
})
export class Marketplace implements OnInit {
  subs: Subscription[] = [];
  companies: any = [];
  filterStep = 1;

  searchControl = new FormControl('');
  filterForm = new FormGroup({});

  constructor(
    private sharedService: SharedService,
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this._initFilterForm();
    this.store.dispatch(getCompaniesForSaleList({data: this._getFilterValues()}));
    this._getOrdersListener();

    this._searchChangeListerer();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getOrdersListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompaniesForSaleListSuccess.type))
        .subscribe(({ data }: any) => {
          this.companies = [];
          if (data && data.length) {
            let arr = (data as any).filter((e: any) => e.state === 4);
            while(arr.length) this.companies.push(arr.splice(0,2));
          }
        })
    );
  }

  private _initFilterForm() {
    this.filterForm.addControl(
      'isHasProperty',
      new FormControl(false)
    );
    this.filterForm.addControl(
      'isHasBankAccounts',
      new FormControl(false)
    );
    this.filterForm.addControl(
      'isZeroBalance',
      new FormControl(false)
    );
    this.filterForm.addControl(
      'isCanGetCitizenship',
      new FormControl(false)
    );
    this.filterForm.addControl(
      'isHasLocalDirector',
      new FormControl(false)
    );
    this.filterForm.addControl(
      'priceForSaleFrom',
      new FormControl(0)
    );
    this.filterForm.addControl(
      'priceForSaleTo',
      new FormControl(0)
    );
  }

  private _searchChangeListerer() {
    this.subs.push(
      this.searchControl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe ((value: any) => {
          console.log(value);
          this.store.dispatch(getCompaniesForSaleList({data: Object.assign(this._getFilterValues(), {'search': value})}));
        })
    );
  }

  private _getFilterValues() {
    let props = {};
    let filteredArray = Object.entries(this.filterForm.value)
      .filter(e => e[1])
      .map(e => e[0]);
    if (filteredArray.length) {
      filteredArray.map((e: any) => {
        Object.assign(props, { [e]: true })
      });
    }
    return props;
  }

  private _incrementViews(id: string) {
    this.sharedService.addViewToCompany(id)
    .subscribe((_) => {
      // this.router.navigate([`/account/marketplace/${id}`]);
    });
  }

  public getPrefix(prefix: string) {
    return prefix.split(' ')[0] || '';
  }

  public onFilterSubmit() {
    this.store.dispatch(getCompaniesForSaleList({data: this._getFilterValues()}));
  }

  public checkPriceValues() {
    if (!this.filterForm.get('priceForSaleFrom')!.value) {
      this.filterForm.get('priceForSaleFrom')!.setValue(0 as never);
    }
    if (!this.filterForm.get('priceForSaleTo')!.value) {
      this.filterForm.get('priceForSaleTo')!.setValue(0 as never);
    }
    this.filterStep = 1;
  }

  public onOpenCompany(company: any) {
    this._incrementViews(company.id);
    this.router.navigate([`/account/marketplace/${company.id}`]);
  }
}
