<div *ngIf="company" class="put-company-on-sale-page">
	<div class="title">
		The value of my company
	</div>

	<div class="price-container">
		<div class="price">{{ price }}</div>
		<div class="currency">{{ company.currency }}</div>
	</div>

	<div class="slider-container">
		<input
      type="range"
      class="select-scroller__input"
      [min]="10000"
      [max]="100000"
      [step]="100"
      [(ngModel)]="price"
    />

    <div class="period-container">
    	<div class="period-text">6 months</div>
    	<div class="period-text">2 weeks</div>
    	<div class="period-text">1 week</div>
    	<div class="period-text">5 days</div>
    </div>
	</div>

	<div class="description">
		Какой то текст который описывает зависимость время продажи от выставленой цены Какой то текст который описывает зависимость время продажи от выставленой ценыКакой то текст который описывает зависимость время продажи от выставленой цены
	</div>

	<button class="sale-button" (click)="onPutOnSaleButton()">List a company</button>
</div>