import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';

import {
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
  putCompanyOnSale,
  putCompanyOnSaleSuccess,
  putCompanyOnSaleFailure,
} from 'app/store/Shared/shared.actions';

@Component({
    selector: 'PutCompanyForSalePage',
    templateUrl: './ui/PutCompanyForSalePage.component.html',
    styleUrls: ['./ui/PutCompanyForSalePage.component.scss'],
})
export class PutCompanyForSalePage implements OnInit {
  subs: Subscription[] = [];
  company: any;
  price = 55000;
  id: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actions: Actions,
    private store: Store<any>,
  ) {}

  ngOnInit() {
    this._getCompanyInfo();
    this._getCompanyListener();
    this._listCompanyListened();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getCompanyInfo() {
    this.id =
      this.route && this.route.snapshot && this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(getCompanyById({
      id: this.id
    }));
  }

  private _getCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdSuccess.type))
        .subscribe(({ data }: any) => {
          if (data) {
            if (data.state !== 3) {
              this.router.navigate([`/account/marketplace/`]);
            }
            this.company = data;
          }
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdFailure.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/`]);
        })
    );
  }

  private _listCompanyListened() {
    this.subs.push(
      this.actions
        .pipe(ofType(putCompanyOnSaleSuccess.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/`]);
        })
    );
  }

  public onPutOnSaleButton() {
    this.store.dispatch(putCompanyOnSale({
      data: {
        id: this.id,
        price: this.price
      }
    }))
  }
}


